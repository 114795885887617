import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PageContent from '../components/PageContent';

const DefaultPageTemplate = ({
  data: {
    contentfulPage: { title, content },
  },
}) => (
  <Layout>
    <main>
      <PageContent title={title} content={content} />
    </main>
  </Layout>
);

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      metaDescription
      title
      content {
        raw
      }
      slug
    }
  }
`;

export default DefaultPageTemplate;

export const Head = ({
  data: {
    contentfulPage: { title, metaDescription },
  },
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
    </>
  );
};
